<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item>价格管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <div class="main-content">
        <div class="action" v-if="!isHeadquarters">
          <a-button type="white" @click="onExport">
            导出
          </a-button>
        </div>
        <a-table
          :data-source="tblData"
          :columns="columns"
          :pagination="pagination"
          :row-selection="rowSelection"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <a-space slot="strategyName" slot-scope="text, record">
            {{ record.strategyName || "--" }}
          </a-space>
          <template slot="timeScope" slot-scope="text, record">
            <div>
              起：{{
                record.effectuateStartDate
                  ? dtFormat(record.effectuateStartDate)
                  : "--"
              }}
            </div>
            <div>
              止：{{
                record.effectuateEndDate
                  ? dtFormat(record.effectuateEndDate)
                  : "长期"
              }}
            </div>
          </template>
          <div
            slot="fileForm"
            slot-scope="text, record"
            v-viewer
            class="product-img-container"
          >
            <div v-if="record.fileForm" class="product-img-container">
              <img
                v-if="record.fileForm.fileUrl"
                :src="imageBaseUrl + record.fileForm.fileUrl"
                :data-source="imageBaseUrl + record.fileForm.fileUrl"
                alt="商品图片"
              />
            </div>
          </div>
          <a-space slot="action" slot-scope="text, record">
            <a @click="goDetail(record.statisticsId)">
              查看
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { dtFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import { exportPrice, fetchPriceManageList } from "@/services/ProductService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import { fetchCorporationList } from "@/services/ActivityService";

const columns = [
  {
    title: "商品图",
    dataIndex: "fileForm",
    scopedSlots: { customRender: "fileForm" },
    width: "10%"
  },
  {
    title: "产品类型",
    dataIndex: "productType"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "制定机构",
    dataIndex: "corporationName"
  },
  {
    title: "策略名称",
    dataIndex: "strategyName",
    scopedSlots: { customRender: "strategyName" }
  },
  {
    title: "进货价",
    dataIndex: "tradePrice"
  },
  {
    title: "作用地区",
    dataIndex: "areaOfAction"
  },
  {
    title: "实际生效时间",
    dataIndex: "timeScope",
    scopedSlots: { customRender: "timeScope" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "产品类型",
          key: "productType",
          component: "j-select",
          options: [
            { value: "", label: "全部" },
            { value: "VEHICLE", label: "整车" },
            { value: "MOUNTINGS", label: "配件" }
          ]
        },
        {
          label: "商品名称",
          key: "skuName",
          component: "j-input"
        },
        {
          label: "商品编码",
          key: "skuCode",
          component: "j-input"
        },
        {
          label: "制定机构",
          key: "corporationId",
          component: "j-select-search",
          options: [],
          getOptions: () => {
            return this.onLoadCorporation();
          }
        },
        {
          label: "实际生效时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      dtFormat,
      form: {},
      editForm: this.$form.createForm(this),
      tblData: [],
      imageBaseUrl,
      organizationList: [],
      columns,
      visible: false,
      areaCode: [], // 选择的省市区code
      reportAreaCode: [], // 上传的省市区code
      selectedRows: [],
      isHeadquarters: false,
      areaTreeData: [], // 行政区数据
      selectedRowKeys: [],
      buildHeaders,
      baseURL,
      headers: buildHeaders(),
      hasAllDelete: false,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: ""
    };
  },
  computed: {
    rowSelection() {
      const { selectedRows } = this;
      const { selectedRowKeys } = this;
      return {
        selectedRows,
        selectedRowKeys,
        onChange: this.onSelectChange
      };
    }
  },
  created() {
    if (!this.isHeadquarters) {
      this.fields.push({
        label: "作用地区",
        key: "provinceCode,cityCode,areaCode",
        component: "j-cascader",
        fieldNames: {
          label: "areaName",
          value: "areaCode",
          children: "subAreas"
        },
        areaData: [],
        getAreaData: () => {
          return this.onLoadArea();
        }
      });
    }
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    //加载机构信息
    async onLoadCorporation() {
      const resp = await fetchCorporationList("formulateOrganization");
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          if (item.corporationType === "operationHeadquarters") {
            this.organizationList.push({
              label: item.corporationName,
              value: item.distributorId
            });
          }
        });
      }
      return this.organizationList;
    },
    // 加载行政区数据
    async onLoadArea() {
      this.areaTreeData = [];
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaTreeData = resp.data.data;
        this.areaTreeData.unshift({
          areaCode: "00",
          areaName: "全国"
        });
      }
      return this.areaTreeData;
    },
    // 获取列表信息
    fetchList() {
      const params = {
        ...this.form,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        effectuateStartDate: this.form.startTime
          ? this.form.startTime + " 00:00:00"
          : "",
        effectuateEndDate: this.form.endTime
          ? this.form.endTime + " 23:59:59"
          : ""
      };
      params.areaCode = "";
      if (this.form.provinceCode) {
        params.areaCode = this.form.provinceCode;
      }
      if (this.form.cityCode) {
        params.areaCode = this.form.cityCode;
      }
      if (this.form.areaCode) {
        params.areaCode = this.form.areaCode;
      }
      fetchPriceManageList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              if (item.importTime) {
                item.importTime = dtFormat(item.importTime);
              } else {
                item.importTime = "--";
              }
              if (!item.productSerialNo) {
                item.productSerialNo = "--";
              }
              if (item.deliveryTime) {
                item.deliveryTime = dtFormat(item.deliveryTime);
              } else {
                item.deliveryTime = "--";
              }
            });
          }
          this.tblData = records;
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 批量导出
    onExport() {
      exportPrice({
        ...this.form,
        statisticsIdList: encodeURIComponent(
          this.selectedRows.map(r => r.statisticsId).join(",")
        )
      }).then(resp => {
        if (resp.status === 200) {
          this.$message.success("导出成功");
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fURL = document.createElement("a");
          fURL.href = fileURL;
          fURL.setAttribute("download", "商品价格统计.xlsx");
          document.body.appendChild(fURL);
          fURL.click();
          this.selectedRowKeys = [];
          this.selectedRows = [];
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.pagination.current = 1;
      this.fetchList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    //前往详情页
    goDetail(id) {
      this.$router.push({
        name: "ProductValueDetail",
        params: {
          id: id
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-reset {
  margin-bottom: 8px;
}

.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.search__btn {
  padding-left: 58px;
}

.search__btn button {
  margin-right: 8px;
}
.action {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.action button {
  margin-right: 8px;
}
.action .float {
  border-color: rgb(252, 88, 92);
  color: rgb(252, 88, 92);
}
.product-img-container {
  width: 100px;
  height: 100px;
}
.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-operation {
  cursor: pointer;
}
.col {
  flex: 1;
  display: flex;
  align-items: center;
}
/deep/.ant-space-item {
  white-space: nowrap;
}
/deep/.ant-table-thead > tr > th {
  white-space: nowrap;
}
/deep/.ant-modal {
  width: 720px !important;
}
.advanced-search-forms/deep/ .ant-row {
  display: flex;
  justify-content: space-between;
}
.advanced-search-forms/deep/ .ant-row .ant-col-10 {
  flex: 1;
}
.advanced-search-forms/deep/ .ant-row .ant-col-10:first-child {
  margin-right: 30px;
}
.advanced-search-forms/deep/
  .ant-row
  .ant-col
  .ant-form-item
  .ant-form-item-label {
  min-width: 81px;
}
</style>
